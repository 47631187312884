var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Basico")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Viajes")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" ")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"col-6 pt-2 pb-2"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Formulario "+_vm._s(_vm.accion)+" Viajes")])]),_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_vm._m(1),_c('li',{staticClass:"nav-item"},[(_vm.form.guia_id)?_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Guias","data-toggle":"tab","href":"#Guias"}},[_vm._v("Guia")]):_vm._e()])]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Principal"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"tipo_vehiculo"}},[_vm._v("Tipo Vehiculo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tipo_vehiculo_id),expression:"form.tipo_vehiculo_id"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.tipo_vehiculo_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "tipo_vehiculo_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipoVehiculo),function(tipoVehi){return _c('option',{key:tipoVehi.id,domProps:{"value":tipoVehi.id}},[_vm._v(" "+_vm._s(tipoVehi.nombre)+" ")])})],2)]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"vehiculo"}},[_vm._v("Vehículo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vehiculo.placa),expression:"vehiculo.placa"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.vehiculo_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"disabled":!(
                            _vm.sitioCargue &&
                            _vm.form.fecha_inicial &&
                            _vm.form.hora_inicial
                          ),"type":"text","label":"placa"},domProps:{"value":(_vm.vehiculo.placa)},on:{"change":function($event){return _vm.buscarVehiculos()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.vehiculo, "placa", $event.target.value)}}})]),(_vm.form.tipo_vehiculo_id != 6)?_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"remolque"}},[_vm._v("Remolque")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.remolque.placa),expression:"remolque.placa"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.remolque_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"disabled":!(
                            _vm.sitioCargue &&
                            _vm.form.fecha_inicial &&
                            _vm.form.hora_inicial
                          ),"type":"text","label":"placa"},domProps:{"value":(_vm.remolque.placa)},on:{"change":function($event){return _vm.buscarRemolques()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.remolque, "placa", $event.target.value)}}})]):_vm._e(),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"conductor"}},[_vm._v("Conductor")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.conductor.numero_documento),expression:"conductor.numero_documento"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.conductor_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"disabled":!(
                            _vm.sitioCargue &&
                            _vm.form.fecha_inicial &&
                            _vm.form.hora_inicial
                          ),"type":"text","label":"nombre"},domProps:{"value":(_vm.conductor.numero_documento)},on:{"change":function($event){return _vm.buscarConductores()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.conductor, "numero_documento", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"transportadora"}},[_vm._v("Transportadora")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                          _vm.$v.form.transportadora_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        ],attrs:{"placeholder":"Nombre","label":"razon_social","options":_vm.listasForms.transportadoras,"filterable":false},on:{"search":_vm.buscarTransportadoras,"input":function($event){return _vm.selectTransportadora()}},model:{value:(_vm.transportadora),callback:function ($$v) {_vm.transportadora=$$v},expression:"transportadora"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"ruta"}},[_vm._v("Ruta")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:_vm.$store.getters.getDarkMode ? 'dark-vselect' : '',attrs:{"disabled":_vm.acto == 1,"placeholder":"Nombre","label":"nombre","options":_vm.listasForms.rutas,"filterable":false},on:{"search":_vm.buscarRuta,"input":function($event){return _vm.selectRuta()}},model:{value:(_vm.ruta),callback:function ($$v) {_vm.ruta=$$v},expression:"ruta"}})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"sitio"}},[_vm._v("Sitio")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.sitio),expression:"form.sitio"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.form.sitio)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "sitio", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"sitio_cargue"}},[_vm._v("Sitio Cargue")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                          _vm.$v.form.sitio_cargue_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        ],attrs:{"disabled":_vm.acto == 1,"placeholder":"Nombre","label":"nombre","options":_vm.listasForms.sitios,"filterable":false},on:{"search":_vm.buscarSitio,"input":function($event){return _vm.selectSitioCargue()}},model:{value:(_vm.sitioCargue),callback:function ($$v) {_vm.sitioCargue=$$v},expression:"sitioCargue"}})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"orden_cargue"}},[_vm._v("Orden de cargue")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.orden_de_cargue),expression:"form.orden_de_cargue"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.orden_de_cargue.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"number","id":"orden_cargue"},domProps:{"value":(_vm.form.orden_de_cargue)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "orden_de_cargue", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"fecha_inicial"}},[_vm._v("Fecha inicial")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_inicial),expression:"form.fecha_inicial"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_inicial.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"date","id":"fecha_inicial"},domProps:{"value":(_vm.form.fecha_inicial)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_inicial", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"hora_inicial"}},[_vm._v("Hora inicial")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hora_inicial),expression:"form.hora_inicial"},{name:"mask",rawName:"v-mask",value:({
                          mask: '99:99',
                          hourFormat: '24',
                        }),expression:"{\n                          mask: '99:99',\n                          hourFormat: '24',\n                        }"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.hora_inicial.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.form.hora_inicial)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "hora_inicial", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"fecha_final"}},[_vm._v("Fecha final")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_final),expression:"form.fecha_final"}],staticClass:"form-control form-control-sm",attrs:{"readonly":_vm.acto <= 2,"type":"date","id":"fecha_final"},domProps:{"value":(_vm.form.fecha_final)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_final", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"hora_final"}},[_vm._v("Hora final")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hora_final),expression:"form.hora_final"},{name:"mask",rawName:"v-mask",value:({
                          mask: '99:99',
                          hourFormat: '24',
                        }),expression:"{\n                          mask: '99:99',\n                          hourFormat: '24',\n                        }"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.hora_final.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"text","readonly":_vm.acto <= 2,"id":"fecha_final"},domProps:{"value":(_vm.form.hora_final)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "hora_final", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"fecha_cita"}},[_vm._v("Fecha Cita")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.fecha_cita),expression:"form.fecha_cita"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.fecha_cita.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"date","id":"fecha_cita"},domProps:{"value":(_vm.form.fecha_cita)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "fecha_cita", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"hora_cita"}},[_vm._v("Hora Cita")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hora_cita),expression:"form.hora_cita"},{name:"mask",rawName:"v-mask",value:({
                          mask: '99:99',
                          hourFormat: '24',
                        }),expression:"{\n                          mask: '99:99',\n                          hourFormat: '24',\n                        }"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.hora_cita.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"text","id":"hora_cita"},domProps:{"value":(_vm.form.hora_cita)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "hora_cita", $event.target.value)}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"clase_viaje"}},[_vm._v("Clase viaje")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.clase_viaje),expression:"form.clase_viaje"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.clase_viaje.$invalid
                            ? 'is-invalid'
                            : 'is-valid',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "clase_viaje", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.claseViaje),function(estado){return _c('option',{key:estado.numeracion,domProps:{"value":estado.numeracion}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")])})],2)]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"tipo_mercancia"}},[_vm._v("Tipo mercancia")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tipo_producto_id),expression:"form.tipo_producto_id"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.tipo_producto_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "tipo_producto_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipoProducto),function(prod){return _c('option',{key:prod.id,domProps:{"value":prod.id}},[_vm._v(" "+_vm._s(prod.nombre)+" ")])})],2)]),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"vigencia_guia"}},[_vm._v("Tipo operacion viaje")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tipo_operacion),expression:"form.tipo_operacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.tipo_operacion.$invalid
                            ? 'is-invalid'
                            : 'is-valid',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "tipo_operacion", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipoOperacion),function(operacion){return _c('option',{key:operacion.numeracion,domProps:{"value":operacion.numeracion}},[_vm._v(" "+_vm._s(operacion.descripcion)+" ")])})],2)])])])])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('button',{staticClass:"btn bg-secondary col-md-1",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")]),(
                    _vm.$store.getters.can(
                      'hidrocarburos.ventanasOperaciones.crearViaje'
                    )
                  )?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form.$invalid),expression:"!$v.form.$invalid"}],staticClass:"btn bg-primary col-md-1",on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_c('br'),_vm._v("Guardar ")]):_vm._e()])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Viajes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-Principal","data-toggle":"tab","href":"#Principal"}},[_vm._v("Principal")])])
}]

export { render, staticRenderFns }