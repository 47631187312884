<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Viajes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Basico</li>
                  <li class="breadcrumb-item active">Viajes</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-6 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Viajes</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Guias"
                    data-toggle="tab"
                    href="#Guias"
                    v-if="form.guia_id"
                    >Guia</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-2">
                        <label for="tipo_vehiculo">Tipo Vehiculo</label>
                        <select
                          v-model="form.tipo_vehiculo_id"
                          class="form-control form-control-sm"
                          :class="
                            $v.form.tipo_vehiculo_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        >
                          <option>Seleccione...</option>
                          <option
                            v-for="tipoVehi in listasForms.tipoVehiculo"
                            :key="tipoVehi.id"
                            :value="tipoVehi.id"
                          >
                            {{ tipoVehi.nombre }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-2">
                        <label for="vehiculo">Vehículo</label>
                        <input
                          :disabled="
                            !(
                              sitioCargue &&
                              form.fecha_inicial &&
                              form.hora_inicial
                            )
                          "
                          type="text"
                          class="form-control form-control-sm"
                          label="placa"
                          v-model="vehiculo.placa"
                          @change="buscarVehiculos()"
                          :class="
                            $v.form.vehiculo_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                      <div
                        class="form-group col-md-2"
                        v-if="form.tipo_vehiculo_id != 6"
                      >
                        <label for="remolque">Remolque</label>
                        <input
                          :disabled="
                            !(
                              sitioCargue &&
                              form.fecha_inicial &&
                              form.hora_inicial
                            )
                          "
                          type="text"
                          class="form-control form-control-sm"
                          label="placa"
                          v-model="remolque.placa"
                          @change="buscarRemolques()"
                          :class="
                            $v.form.remolque_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>

                      <div class="form-group col-md-2">
                        <label for="conductor">Conductor</label>
                        <input
                          :disabled="
                            !(
                              sitioCargue &&
                              form.fecha_inicial &&
                              form.hora_inicial
                            )
                          "
                          type="text"
                          class="form-control form-control-sm"
                          label="nombre"
                          v-model="conductor.numero_documento"
                          @change="buscarConductores()"
                          :class="
                            $v.form.conductor_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>

                      <!-- <div class="form-group col-md-3">
                        <label for="conductor">Conductor</label>
                        <v-select
                      :class="
                        $store.getters.getDarkMode ? 'dark-vselect' : ''
                      "
                          :disabled="acto == 1"
                          v-model="conductor"
                          placeholder="Documento"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.conductores"
                          :filterable="false"
                          :class="
                            $v.form.conductor_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          @search="buscarConductores"
                          @input="selectConductor()"
                        ></v-select>
                      </div> -->
                      <div class="form-group col-md-4">
                        <label for="transportadora">Transportadora</label>
                        <v-select
                          :class="[
                            $v.form.transportadora_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          v-model="transportadora"
                          placeholder="Nombre"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.transportadoras"
                          :filterable="false"
                          @search="buscarTransportadoras"
                          @input="selectTransportadora()"
                        ></v-select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-4">
                        <label for="ruta">Ruta</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          :disabled="acto == 1"
                          v-model="ruta"
                          placeholder="Nombre"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.rutas"
                          :filterable="false"
                          @search="buscarRuta"
                          @input="selectRuta()"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-3">
                        <label for="sitio">Sitio</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.sitio"
                          readonly
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <label for="sitio_cargue">Sitio Cargue</label>
                        <v-select
                          :class="[
                            $v.form.sitio_cargue_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid',
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                          :disabled="acto == 1"
                          v-model="sitioCargue"
                          placeholder="Nombre"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.sitios"
                          :filterable="false"
                          @search="buscarSitio"
                          @input="selectSitioCargue()"
                        ></v-select>
                      </div>

                      <div class="form-group col-md-2">
                        <label for="orden_cargue">Orden de cargue</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          id="orden_cargue"
                          v-model="form.orden_de_cargue"
                          :class="
                            $v.form.orden_de_cargue.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>

                      <div class="form-group col-md-2">
                        <label for="fecha_inicial">Fecha inicial</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          id="fecha_inicial"
                          v-model="form.fecha_inicial"
                          :class="
                            $v.form.fecha_inicial.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>

                      <div class="form-group col-md-2">
                        <label for="hora_inicial">Hora inicial</label>
                        <!-- <input
                          type="time"
                          class="form-control form-control-sm"
                          id="fecha_final"
                          v-model="form.hora_inicial"
                          :class="
                            $v.form.hora_inicial.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        /> -->
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.hora_inicial"
                          :class="
                            $v.form.hora_inicial.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="fecha_final">Fecha final</label>
                        <input
                          :readonly="acto <= 2"
                          type="date"
                          class="form-control form-control-sm"
                          id="fecha_final"
                          v-model="form.fecha_final"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="hora_final">Hora final</label>
                        <!-- <input
                          :readonly="acto <= 2"
                          type="time"
                          class="form-control form-control-sm"
                          id="fecha_final"
                          v-model="form.hora_final"
                        /> -->
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          :readonly="acto <= 2"
                          v-model="form.hora_final"
                          id="fecha_final"
                          :class="
                            $v.form.hora_final.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="fecha_cita">Fecha Cita</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          id="fecha_cita"
                          v-model="form.fecha_cita"
                          :class="
                            $v.form.fecha_cita.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="hora_cita">Hora Cita</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.hora_cita"
                          id="hora_cita"
                          :class="
                            $v.form.hora_cita.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-2">
                        <label for="clase_viaje">Clase viaje</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="form.clase_viaje"
                          :class="
                            $v.form.clase_viaje.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.claseViaje"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-2">
                        <label for="tipo_mercancia">Tipo mercancia</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="form.tipo_producto_id"
                          :class="
                            $v.form.tipo_producto_id.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="prod in listasForms.tipoProducto"
                            :key="prod.id"
                            :value="prod.id"
                          >
                            {{ prod.nombre }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-md-2">
                        <label for="vigencia_guia">Tipo operacion viaje</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="form.tipo_operacion"
                          :class="
                            $v.form.tipo_operacion.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="operacion in listasForms.tipoOperacion"
                            :key="operacion.numeracion"
                            :value="operacion.numeracion"
                          >
                            {{ operacion.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="
                      $store.getters.can(
                        'hidrocarburos.ventanasOperaciones.crearViaje'
                      )
                    "
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import { required, helpers } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "ViajeForm",
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      falgValidando: false,
      cargando: false,
      form: {
        guia_id: null,
        tipo_vehiculo_id: null,
        vehiculo_id: null,
        conductor_id: null,
        remolque_id: null,
        transportadora_id: null,
        producto_liquido_id: null,
        fecha_inicial: null,
        hora_inicial: null,
        fecha_final: null,
        hora_final: null,
        fecha_cita: null,
        hora_cita: null,
        ruta_id: null,
        orden_de_cargue: null,
        clase_viaje: null,
        vigencia_guia: null,
        sitio_type: "App\\Sitio",
        sitio_id: null,
        sitio_cargue_id: null,
        estado: 1,
      },
      empresa_id: null,
      id: null,
      //arreglos
      accion: "",
      acto: null,
      metodo: "",
      vehiculo: [],
      conductor: [],
      remolque: [],
      transportadora: [],
      producto_liquido: [],
      sitio: [],
      sitioCargue: [],
      ruta: [],
      sitio_type: null,
      sitio_id: null,
      tipo_control: null,
      //listas
      listasForms: {
        vehiculos: [],
        conductores: [],
        remolques: [],
        transportadoras: [],
        productos_liquidos: [],
        rutas: [],
        sitios: [],
        claseViaje: [],
        tipoOperacion: [],
        tipoVehiculo: [],
        tipoProducto: [],
      },
    };
  },
  validations() {
    if (this.form.tipo_vehiculo_id != 6) {
      return {
        form: {
          vehiculo_id: {
            required,
          },
          conductor_id: {
            required,
          },
          remolque_id: {
            required,
          },
          transportadora_id: {
            required,
          },
          clase_viaje: {
            required,
          },
          fecha_inicial: {
            required,
          },
          hora_inicial: {
            required,
            timeFormat,
          },
          fecha_cita: {
            required,
          },
          hora_cita: {
            required,
            timeFormat,
          },
          sitio_cargue_id: {
            required,
          },
          orden_de_cargue: {
            required,
          },
          sitio_id: {
            required,
          },
          tipo_operacion: {
            required,
          },
          tipo_vehiculo_id: {
            required,
          },
          tipo_producto_id: {
            required,
          },
          hora_final: {
            timeFormat,
          },
        },
      };
    } else {
      return {
        form: {
          vehiculo_id: {
            required,
          },
          conductor_id: {
            required,
          },
          transportadora_id: {
            required,
          },
          clase_viaje: {
            required,
          },
          fecha_inicial: {
            required,
          },
          hora_inicial: {
            required,
            timeFormat,
          },
          fecha_cita: {
            required,
          },
          hora_cita: {
            required,
            timeFormat,
          },
          sitio_cargue_id: {
            required,
          },
          orden_de_cargue: {
            required,
          },
          sitio_id: {
            required,
          },
          tipo_operacion: {
            required,
          },
          tipo_vehiculo_id: {
            required,
          },
          tipo_producto_id: {
            required,
          },
          hora_final: {
            timeFormat,
          },
        },
      };
    }
  },
  methods: {
    init() {
      this.cargando = true;
      this.sitio_type = this.$route.params.sitio_type;
      this.sitio_id = this.$route.params.sitio_id;
      this.tipo_control = this.$route.params.tipo_control;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.metodo = "PUT";
        this.vehiculo = {
          id: this.form.vehiculo.id,
          placa: this.form.vehiculo.placa,
        };
        this.remolque = {
          id: this.form.remolque.id,
          placa: this.form.remolque.placa,
        };
        this.conductor = {
          id: this.form.conductor.id,
          nombre:
            this.form.conductor.nombres + " " + this.form.conductor.apellidos,
        };
        this.ruta = {
          id: this.form.ruta.id,
          nombre: this.form.ruta.nombre,
        };
        this.transportadora = {
          id: this.form.transportadora.id,
          razon_social: this.form.transportadora.razon_social,
        };

        if (this.$route.params.sitio_type == 1) {
          this.form.sitio_type = "App\\Sitio";
        } else if (this.$route.params.sitio_type == 2) {
          this.form.sitio_type = "App\\PuntoGestion";
        }

        this.form.sitio = this.form.sitio.nombre;

        this.sitioCargue = {
          id: this.form.sitio_cargue.id,
          nombre: this.form.sitio_cargue.nombre,
        };

        this.acto = 1;
      } else {
        this.metodo = "POST";
        this.id = this.$route.params.id;
        this.acto = this.$route.params.acto;
        this.form.sitio_id = this.$route.params.sitio_id;
        this.form.sitio_type = this.$route.params.sitio_type;
        this.form.sitio = this.$route.params.sitio;

        if (this.$route.params.sitio_type == 1) {
          this.form.sitio_type = "App\\Sitio";
        } else if (this.$route.params.sitio_type == 2) {
          this.form.sitio_type = "App\\PuntoGestion";
        }
      }
    },

    getTipoOperacion() {
      axios.get("/api/lista/57").then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].numeracion == 1)
            this.listasForms.tipoOperacion.push(response.data[i]);
        }
      });
    },

    getEstadosVigencia() {
      axios.get("/api/lista/45").then((response) => {
        this.listasForms.estadosVigencia = response.data;
      });
    },

    getEstadosClase() {
      axios.get("/api/lista/43").then((response) => {
        this.listasForms.claseViaje = response.data;
      });
    },

    getTiposVehiculos() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipoVehiculo = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTiposProducto() {
      axios.get("/api/admin/productos/lista").then((response) => {
        this.cargando = false;
        this.listasForms.tipoProducto = response.data;
      });
    },
    /* 
    buscarVehiculos(search) {
      let me = this;
      me.listasForms.vehiculos = [];
      if (search != "") {
        var url = "api/admin/vehiculos/lista?placa=" + search;
        axios
          .get(url, {
            params: {
              empresa_id: me.empresa_id,
            },
          })
          .then(function(response) {
            me.listasForms.vehiculos = response.data;
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    }, */

    async validarStatus(dato, tipo) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "api/hidrocarburos/postulaciones/listaConductores";
      } else if (tipo == "R") {
        entidad = "Remolque";
        url = "api/hidrocarburos/postulaciones/listaRemolques";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "api/hidrocarburos/postulaciones/listaVehiculos";
      }

      let params = {
        entidad,
        dato,

        sitio_id: this.sitioCargue.id,
      };

      var res = null;
      await axios
        .get(url, {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function(e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },
    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let erroHtml = "<div>";
        if (res.validacion.error.documentos) {
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml += res.validacion.error.documentos.obligatorios.join(
              "<br>"
            );
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml += res.validacion.error.documentos.no_verificados.join(
              "<br>"
            );
          }
          erroHtml += "</div>";
          await this.$swal({
            html: erroHtml,
            showCancelButton: false,
            focusConfirm: true,
            icon: "error",
            title: "Aviso en documentacion",
            showConfirmButton: false,
            showCloseButton: true,
            footer:
              "<small style='text-align:center'><b>Desde el 1 de agosto se aplicaran las restricciones</b>, por favor tenga al dia la documentacion de sus vehiculos, para mas informacion comuniquese con la mesa de ayuda al siguiente numero <b>3176431376 Opción 3</b></small>",
          });
        } else {
          this.$swal({
            icon: "error",
            title: res.validacion.error.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },

    async buscarVehiculos() {
      this.falgValidando = true;
      let me = this;
      let dato = this.vehiculo.placa;
      let res = await this.validarStatus(dato, "V");
      if (res && res.length != 0) {
        if (res.validacion.error) {
          this.vehiculo = {};
          this.form.vehiculo = {};
          await this.msgDocumentacion(res);
          this.falgValidando = false;
          return false;
        }

        if (res.viaje.length > 0) {
          this.vehiculo = {};
          this.form.vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El vehículo se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        } else {
          this.selectVehiculo(res[0].id);
        }

        //Validar si ya se encuentra con una postulacion
        if (res.postulacion.length > 0) {
          this.vehiculo = {};
          this.form.vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El vehículo ya está postulado para la fecha ${this.postulaciones.solicitud_diaria.fecha}`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        //Validar si el vehículo tiene peso asignado.
        if (res[0].peso_vacio == null || res[0].peso_vacio == 0) {
          this.vehiculo = {};
          this.form.vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El vehículo no tiene peso asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        /*  //Valida el modelo para el trayecto de la ruta larga
        if (tipo_ruta == 2) {
          if (res.modelo) {
            this.vehiculo = res[0];
            this.form.vehiculo = res[0];
          } else {
            this.vehiculo = {};
            this.form.vehiculo = {};
            this.$swal({
              icon: "error",
              title:
                "El vehículo no cumple con las condiciones para ruta larga",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            return false;
          }
        }
 */
        //Valida reporte satelital del vehiculo
        if (res.ultimoRegistro) {
          if (res.ultimoRegistro.error) {
            this.vehiculo = {};
            this.form.vehiculo = {};
            this.$swal({
              icon: "error",
              title: `Error GPS: ${res.ultimoRegistro.error.msg}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            return false;
          } else if (res.ultimoRegistro.success) {
            this.vehiculo = res[0];
            this.form.vehiculo = res[0];
            this.$swal({
              icon: "success",
              title: `${res.ultimoRegistro.success.msg} Distancia: ${res.ultimoRegistro.success.distance} Km`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            return true;
          }
        }
      } else {
        this.$swal({
          icon: "error",
          title:
            "El vehículo digitado NO se encuentra registrado, pruebe con otra placa.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.falgValidando = false;
        this.vehiculo = {};
        this.form.vehiculo = {};
        return false;
      }
      this.falgValidando = false;
    },
    selectVehiculo(vehId) {
      this.form.vehiculo_id = "";
      if (this.vehiculo) {
        this.form.vehiculo_id = vehId;
      }
    },
    async buscarRemolques() {
      this.falgValidando = true;
      let me = this;
      this.form.remolque = {};
      let dato = this.remolque.placa;
      let res = await this.validarStatus(dato, "R");
      if (res && res.length != 0) {
        if (res.validacion.error) {
          this.remolque = {};
          this.form.remolque = {};
          await this.msgDocumentacion(res);
          this.falgValidando = false;
          return false;
        }

        if (res.viaje.length > 0) {
          this.remolque = {};
          this.form.remolque = {};
          this.$swal({
            icon: "error",
            title: `El remolque se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else if (res.postulacion.length > 0) {
          this.remolque = {};
          this.form.remolque = {};
          this.$swal({
            icon: "error",
            title: `El remolque ya está postulado para la fecha ${this.postulaciones.solicitud_diaria.fecha}`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          //Validar si el rermolque tiene peso asignado.
        } else if (res[0].peso_vacio == null || res[0].peso_vacio == 0) {
          this.remolque = {};
          this.form.remolque = {};
          this.$swal({
            icon: "error",
            title: `El remolque no tiene peso asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else {
          this.selectRemolque(res[0].id);
        }
      } else {
        this.$swal({
          icon: "error",
          title:
            "El remolque digitado NO se encuentra registrado, pruebe con otra placa.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.remolque = {};
        this.form.remolque = {};
      }
      this.falgValidando = false;
    },
    selectRemolque(RemId) {
      this.form.remolque_id = "";
      if (this.remolque) {
        this.form.remolque_id = RemId;
      }
    },

    async buscarConductores() {
      this.falgValidando = true;
      let me = this;
      this.form.conductor = {};
      let dato = this.conductor.numero_documento;

      let res = await this.validarStatus(dato, "C");
      if (res && res.length != 0) {
        if (res.validacion.error) {
          this.falgValidando = false;
          this.form.conductor = {};
          await this.msgDocumentacion(res);
          return false;
        }

        if (res["info"] == false) {
          this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Conductores`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.form.conductor = {};
          return false;
        }

        if (res.viaje.length > 0) {
          this.form.conductor = {};
          this.$swal({
            icon: "error",
            title: `El conductor se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else if (res.postulacion.length > 0) {
          this.form.conductor = {};
          this.$swal({
            icon: "error",
            title: `El conductor ya está postulado para la fecha ${this.postulaciones.solicitud_diaria.fecha}`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else {
          this.selectConductor(res[0].id);
        }
      } else {
        this.$swal({
          icon: "error",
          title:
            "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.form.conductor = {};
      }
      this.falgValidando = false;
    },
    selectConductor(CondId) {
      this.form.conductor_id = "";
      if (this.conductor) {
        this.form.conductor_id = CondId;
      }
    },

    /* buscarRemolques(search) {
      let me = this;
      me.listasForms.remolques = [];
      if (search != "") {
        var url = "api/admin/remolques/lista?placa=" + search;
        axios
          .get(url, {
            params: {
              // empresa_id: me.empresa_id,
            },
          })
          .then(function(response) {
            me.listasForms.remolques = response.data;
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
*/

    /* buscarConductores(search) {
      let me = this;
      me.listasForms.conductores = [];
      if (search != "") {
        var url = "api/admin/conductores/lista?numero_documento=" + search;
        axios
          .get(url, {
            params: {
              //empresa_id: this.empresa_id,
            },
          })
          .then(function(response) {
            me.listasForms.conductores = response.data;
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
*/

    buscarTransportadoras(search) {
      let me = this;
      me.listasForms.transportadoras = [];
      if (search != "") {
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url, {
            params: {
              //empresa_id: this.empresa_id
            },
          })
          .then(function(response) {
            me.listasForms.transportadoras = response.data;
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectTransportadora() {
      this.form.transportadora_id = "";
      if (this.transportadora) {
        this.form.transportadora_id = this.transportadora.id;
      }
    },

    buscarRuta(search) {
      let me = this;
      me.listasForms.rutas = [];
      if (search != "") {
        var url = "api/admin/rutas/lista?nombre=" + search;
        axios
          .get(url, {
            params: {
              //empresa_id: this.empresa_id,
            },
          })
          .then(function(response) {
            me.listasForms.rutas = response.data;
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectRuta() {
      this.form.ruta_id = "";
      if (this.ruta) {
        this.form.ruta_id = this.ruta.id;
      }
    },

    buscarSitio(search) {
      let me = this;
      this.listasForms.sitios = [];
      if (search != "") {
        var url = "api/admin/sitios/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.sitios = response.data;
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectSitioCargue() {
      this.form.sitio_cargue_id = "";
      if (this.sitioCargue) {
        this.form.sitio_cargue_id = this.sitioCargue.id;
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios({
          method: this.metodo,
          url: "/api/hidrocarburos/viajes",
          data: this.form,
        })
          .then(() => {
            this.$swal({
              icon: "success",
              title: "El  viaje se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });

            const sitioID = this.sitio_id;
            const sitioType = this.sitio_type;
            const tipoControl = this.tipo_control;
            this.$router.replace({
              path: `/Hidrocarburos/VentanasOperaciones/${sitioID}/${sitioType}/${tipoControl}`,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    back() {
      return this.$router.replace({
        path: `/Hidrocarburos/VentanasOperaciones/${this.sitio_id}/${this.sitio_type}/${this.tipo_control}`,
      });
    },
  },
  async mounted() {
    await this.init();
    await this.getEstadosVigencia();
    await this.getEstadosClase();
    await this.getTipoOperacion();
    await this.getTiposVehiculos();
    await this.getTiposProducto();
  },
};
</script>
